.inner-container{
    gap: 1.5rem;
    background: black;
    padding: 2rem;
    border-radius: 10px;

    text-align: center;
}

.inner-container .primaryText{
    color: white;
    font-weight: 600;
}
.inner-container .secondaryText{
    color: rgba(255, 255, 255, 0.587);
}
.inner-container .button {
    background: rgba(230, 151, 95, 0.752);
    border: 2px solid white;
    box-shadow: var(--shadow);
    border-radius: 10px;
}