.h-wrapper {
  color: white;
}

.h-container {
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--secondary);
}

.h-menu {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.h-menu > a,
.h-menu > button > a {
  text-decoration: none;
  transition: color 0.3s ease;
}

.h-menu > a:hover,
.h-menu > button > a:hover {
  color: rgba(230, 151, 95, 0.752);
  cursor: pointer;
}

.menu-icon {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 101;
}

@media only screen and (max-width: 768px) {
  .menu-icon {
    display: block;
  }
  .h-menu {
    color: var(--black);
    position: absolute;
    gap: 2rem;
    font-weight: 500;
    flex-direction: column;
    right: 4rem;
    top: 3rem;
    background: white;
    display: flex;
    border-radius: 10px;
    transition: all 200ms ease;
    align-items: flex-start;
    padding: 3rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    z-index: 100;
  }
}
